@import "variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

body {
    .container-slides {
        .slick-dots{
            position: absolute;
            bottom: 90px;
            list-style: none;
            padding: 0;
            width: 90%;
            max-width: 100%;
            margin: auto;
            left: 5%;
            li {
                width: 33%;
                cursor: pointer;
                position: relative;
                text-indent: -9999px;
                margin: 0;
                button {
                    width: 100%;
                    font-size: 16px;
                    text-align: left;
                    color: #ffffff;
                    font-weight: 400;
                    outline: none;
                    position: relative;
                    text-transform: uppercase;
                    padding: 24px 16px;
                    border-top: 1px solid #aaaaaa;
                    &:before {
                        content: "";
                        position: absolute;
                        top: -1px;left: 0;right: 0;
                        height: 0;
                        background-color: #fff;
                        transition: all .5s;
                        width: 100%;
                    }
                }
                &.slick-active {
                    border-top: 4px #ffffff;
                    button {
                        &:before {
                            height: 3px;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 850px) {
            .slick-dots{
                display: none !important;
            }
        }
    }
    .container-header {
        background-size: cover;
        background-position: bottom center;
        height: 1080px;
        max-height: 100vh;
        h1 {
            span {
                &:before {
                    position: absolute;
                    bottom: -6px;
                    height: 32px;
                    left: -4px;
                    right: -4px;
                    content: "";
                    z-index: -1;
                    background: $gradientSecondary;
                }
            }
        }
    }

    #block-youtube {
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        .video-responsive {
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;
        }
    }

    #block-force {
        background-repeat: no-repeat;
        background-position: center right;
        .container-force {
            min-height: 207px;
        }
    }
}
